:root {
    --header-height: 70px;
    --content-height: calc(100% - var(--header-height) - 1px)
}

body {
    margin: 0;
    padding: 0;
}

body, body > div, html, .body {
    height: 100%;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
}

h1.h3 {
    font-weight: 500;
    font-size: 1.2em;
}

h1.h2 {
    font-weight: 500;
    font-size: 1.8em;
}

.cm-editor {
    border: 1px solid lightgrey;
}

.helpButton {
    background: #f5f5f5;
    opacity: .5;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c0c0c0;
    position: absolute;
    top: 16px;
    right: 20px;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    cursor: pointer;
}

.helpButton:hover {
    opacity: .4;
}

.helpButton:active {
    opacity: .3;
}
